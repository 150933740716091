<template>
    <div>
        I am how to buy
        <howToBuy />
    </div>
</template>
<script>
import howToBuy from '../components/home/howToBuy.vue';

export default {
    name: 'howToBuyView',

    components: {
        howToBuy
    }
}
</script>
<style lang="">
    
</style>